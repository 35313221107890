.tableDataRow {
  vertical-align: middle;
  font-size: 14px;
  height: 47px;

  // background-color: #fff;
  :first-child {
    text-align: left;
  }
}
// for table without non linkable rows
.tableRowContainer {
  tr:nth-child(2n) {
    background-color: #fafafb;
  }
  tr:hover {
    background-color: #f3fcff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 99;
  }
}
// end
.link-style-btn {
  // cursor: pointer;
  border: none;
  background: none;
  color: #009de1;
  padding-left: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    content: '';
    display: block;
  }
}
.link-style-deviceList {
  // cursor: pointer;
  border: none;
  background: none;
  color: #313133;
  padding-left: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    content: '';
    display: block;
  }
}
.link-style-device-btn {
  // cursor: pointer;
  border: none;
  background: transparent;
  color: #009de1;
  padding: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link-style-btn:hover {
  color: #007eb4;
}
.tableRowContainerLink {
  color: inherit !important;
  text-decoration: none;
  padding: 0px;
  display: block;
  .tableDataRow {
    &:hover {
      background-color: #edeeef;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 99;
    }
  }
  &:nth-child(2n) {
    .tableDataRow {
      background-color: #fafafb;
      &:hover {
        cursor: pointer;
        background-color: #edeeef;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
  &:last-child {
    tr {
      border-bottom: none;
    }
  }
}
.tableDataRow > a:first-child {
  text-align: left;
}

.tableDataRowSkeleton {
  height: 47px;
  & td {
    // width: 25% !important;
    display: flex;
    &:not(:first-child) {
      justify-content: end;
    }
  }
}
.tableDataRowSkeletonDevice {
  display: flex;
  justify-content: space-between;
  height: 47px;
  & td {
    display: flex;
  }
  & td:nth-child(1) {
    width: 20%;
  }
  & td:nth-child(2) {
    width: 16%;
  }
  & td:nth-child(3) {
    width: 20%;
  }
  & td:nth-child(4) {
    width: 18%;
  }
  & td:nth-child(5) {
    width: 10%;
  }
}

.tableDataRowSkeletonUser {
  height: 47px;
  padding-right: 20px !important;

  & td {
    display: flex;
  }
}

.skeletonCell {
  display: flex;
  &:last-child {
    flex-direction: row-reverse;
  }
}

.skeletonCellGroup {
  display: flex;
  flex-direction: row-reverse;
  &:first-child {
    flex-direction: row;
  }
}

.groupTableDataRow {
  & td:not(:first-child) {
    div {
      text-align: right;
    }
    // padding-right: 100px;
  }

  & td:last-child {
    div {
      text-align: right;
      div:lang(ja) {
        padding-right: 0px;
      }
    }
    // padding-right: 8px;
  }

  &:hover {
    cursor: pointer;
  }
}

.userTableDataRow {
  padding-right: 20px !important;
  & td:first-child {
    padding-right: 50px;
  }
  & td:not(:first-child) {
    div {
      text-align: left;
    }

    padding-right: 50px;
  }

  & td:last-child {
    div {
      text-align: right;
    }

    padding-right: 0px;
  }

  &:hover {
    cursor: pointer;
    background-color: #edeeef !important;
  }

  &:last-child {
    border-bottom: none;
  }
}

.userRegistrationStatus {
  padding: 3px 10px 5px 10px;
  min-height: 25px;
  border-radius: 16px;
  color: #ffffff;
  width: fit-content;
  float: left;
  white-space: pre-wrap !important;
  p {
    // text-align: center !important;
    margin: 0 !important;
  }
}

.deviceTableDataRow {
  & td:not(:last-child) {
    div {
      text-align: left;
      padding-right: 20px;
    }
  }

  & td:last-child {
    padding-right: 9px;
    div {
      text-align: right;
      padding-right: 33px;
    }
    div:lang(ja) {
      padding-right: 0px;
    }
    div:lang(fr) {
      padding-right: 22px;
    }
    div:lang(de) {
      text-align: center;
      padding-right: 0px;
    }
  }
  & td:last-child:lang(ja) {
    padding-right: 0px;
  }
  & td:last-child:lang(de) {
    padding-right: 0px;
  }

  &:last-child {
    border-bottom: none;
  }
  &:hover {
    cursor: pointer;
  }
}

.device-table-header {
  padding-right: 20px !important;
  & td:first-child {
    padding-right: 50px;
  }
  & td:not(:first-child) {
    div {
      text-align: left;
    }

    padding-right: 50px;
  }

  & td:last-child {
    div {
      text-align: left;
    }

    padding-right: 0px;
  }

  &:hover {
    cursor: pointer;
    background-color: #edeeef !important;
  }
}

.transHistoryTableDataRow {
  padding-right: 37px !important;
  & td:first-child {
    padding-right: 50px;
  }
  & td:not(:first-child) {
    div {
      text-align: left;
    }

    padding-right: 50px;
    @media screen and (max-width: '1820px') {
      padding-right: 25px;
    }
    @media screen and (max-width: '1300px') {
      padding-right: 0px;
    }
  }
}

.reportDataRow {
  padding: 0px 37px 0px 20px !important;
  & td:last-child {
    div {
      text-align: right;
    }
    padding-right: 0 !important;
  }
}
@media screen and (min-width: '1820px') {
  .device-list-status {
    white-space: pre-wrap !important;
  }
}
//Commented styles are necessary when we need to show save translation history icon in the group list table
.status-of-remote-settings {
  // padding-right: 70px;
  padding-right: 92px;
}
.status-of-remote-settings:lang(ja) {
  // padding-right: 70px;
  padding-right: 92px;
}
.status-of-remote-settings:lang(fr) {
  // padding-right: 95px;
  padding-right: 117px;
}
.status-of-remote-settings:lang(de) {
  // padding-right: 90px;
  padding-right: 112px;
}
.status-of-remote-settings-isSafari {
  padding-right: 120px;
  // padding-right: 118px;
}
.status-of-remote-settings-isSafari:lang(ja) {
  // padding-right: 50px;
  padding-right: 55px;
}
.status-of-remote-settings-isSafari:lang(fr) {
  // padding-right: 60px;
  padding-right: 65px;
}
.status-of-remote-settings-isSafari:lang(de) {
  // padding-right: 60px;
  padding-right: 65px;
}
