.data-table-wrap {
  margin-top: 20px;
  padding: 0px 1px;
  color: #313133;
}

.data-pagination {
  height: 54px;
  border: 1px solid #d9d9d9;
  border-radius: 0 0 4px 4px;
  margin: 0 1px  ;
  margin-top: 0;
}

@media screen and (max-width: '599px') {
  .data-pagination {
    height: unset;
    min-height: 54px !important;
    padding-bottom: 12px !important;
  }
}