@media screen and (max-width: '1199px') {
  //for user details page
  .user-detail-main-block-res {
    flex-direction: column;
  }
  .profile-card-res {
    width: 100% !important;
  }
  .user-details-setting-card {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: '599px') {
  //for user list and search
  .user-list-for-res {
    flex-direction: column;
    row-gap: 12px;
    align-items: center !important;
    form {
      width: 100%;
    }
  }
  .user-add-dialog-res .MuiDialog-container .MuiDialog-paper {
    width: 100%;
    min-width: auto;
  }
  .react-select__control {
    width: auto !important;
  }
  .user-detail-profile-info-res {
    flex-direction: column;
  }
  .last-login-info {
    align-items: center !important;
  }
  .user-details-setting-card {
    padding: 32px 16px !important;
  }
  .user-details-common-card {
    padding: 32px 16px !important;
  }
}
