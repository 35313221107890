@media screen and (max-width: '899.98px') {
  .sidebar-drawer {
    position: absolute;
  }
  .responsive-content {
    width: calc(100% - 80px);
    margin-left: 80px;
  }
}

// @media screen and (max-width: '450.98px') {
//   .sidebar-drawer {
//     position: absolute;
//     display: none;
//   }
//   .responsive-content {
//     width: 100%;
//     margin-left: 0;
//   }
//   .MuiContainer-root {
//     padding-left: 15px !important;
//     padding-right: 15px !important;
//   }
// }

@media screen and (max-width: '1199px') {
  .table-body-report-res {
    min-width: 600px !important;
  }
}
