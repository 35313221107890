.loaderParentContainer {
  position: absolute;
  width: 100%;
  height: 100vh;

  .loaderContainerCss {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #ffdee9;
    // background-image: linear-gradient(90deg, #ffdee9 0%, #b5fffc 100%);
  }
  .pt-loader-svg {
    translate: 0 -50%;
  }

  
  @keyframes combined {
    0%,
    100% {
      transform: translateY(0) rotate(0deg);
    }
    25% {
      transform: translateY(-30px) rotate(1deg);
    }
    50% {
      transform: translateY(15px) rotate(-2deg);
    }
    75% {
      transform: translateY(-15px) rotate(1deg);
    }
  }

  .anim-path {
    animation: combined 2s infinite;
  }

  .path1 {
    animation-delay: 1.2s;
  }
  .path2 {
    animation-delay: 1.1s;
  }
  .path3 {
    animation-delay: 1s;
  }
  .path4 {
    animation-delay: 0.9s;
  }
  .path5 {
    animation-delay: 0.8s;
  }
  .path6 {
    animation-delay: 0.7s;
  }
  .path7 {
    animation-delay: 0.6s;
  }
  .path8 {
    animation-delay: 0.5s;
  }
  .path9 {
    animation-delay: 0.4s;
  }
  .path10 {
    animation-delay: 0.3s;
  }
  .path11 {
    animation-delay: 0.2s;
  }
  .path12 {
    animation-delay: 0.1s;
  }
  .path13 {
    animation-delay: 0s;
  }
}
