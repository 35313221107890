.view-switch-label {
  margin: 0 11px 0 20px;
  & p {
    margin: 0;
    white-space: nowrap;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
  }
}

.filter {
  max-width: 395px;
  width: 100%;
}
