.user-add-auth-list {
  margin: 16px 0 8px 0;
  display: flex;
}

.user-add-button-container {
  display: flex;
  justify-content: center;
}

.button-display-left {
  padding-right: 1rem;
}

.button-display-right {
  padding-left: 1rem;
}
