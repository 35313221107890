.page-loading-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #eeeeee;
}

.page-loading-position {
  position: absolute;
  top: 50%;
  left: 50%;
}
