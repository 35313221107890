.user-list-flex-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.user-list-flex-item:nth-child(1) {
  flex-grow: 1;
}

.user-info-process-success-message {
  text-align: left;

  &:empty {
    height: 32px;
  }
}

.icon-img {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}

.user-list-flex-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  margin-top: 3px;
}
