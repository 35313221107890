@media screen and (max-width: '1199px') {
  .view-user-search-input {
    width: 100%;
  }
}
@media screen and (max-width: '899px') {
  .search-block {
    flex-direction: column;
    grid-row-gap: 5px;
  }
  .remove-device-setup {
    &-scroll {
      min-width: 100%;
    }
    &-protection-input-form {
      grid-template-columns: none !important;
    }
    &-new-wifi-box {
      width: 100% !important;
    }
    &-new-wifi-sub {
      flex-direction: column;
    }
    &-new-wifi-inner {
      grid-template-columns: none !important;
      max-width: 100% !important;
    }
    &-wifi-setting {
      width: 100% !important;
    }
    &-submit-btn {
      width: 100% !important;
    }
  }
  .remove-device-setup .MuiPaper-root {
    max-width: 98% !important;
    min-width: 98% !important;
  }
  .manage-group-device .MuiPaper-root {
    min-width: 97% !important;
  }
  .manage-group-device {
    &-all-select-device-list {
      flex-direction: column;
    }
    &-left {
      width: 100% !important;
    }
    &-right {
      width: 100% !important;
    }
    &-left .MuiFormControl-root {
      width: 98% !important;
    }
    &-left .MuiFormControl-root .MuiInputBase-root {
      width: 100% !important;
    }
    &-right .MuiFormControl-root {
      width: 98% !important;
    }
    &-right .MuiFormControl-root .MuiInputBase-root {
      width: 100% !important;
    }
    &-all-select-card {
      width: 98% !important;
      min-height: auto !important;
    }
    &-item-box {
      height: 258px !important;
    }
    &-item-list {
      width: 98% !important;
    }
    &-list-container {
      width: 100% !important;
    }
    &-count-block {
      width: 98% !important;
    }
    &-data-not-found-block {
      width: 100% !important;
      height: 258px !important;
    }
    &-data-not-found-icon {
      width: 100% !important;
      height: 258px !important;
      border: none !important;
    }
    &-no-divider {
      display: none;
    }

    &-no-device {
      width: 98%;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      justify-content: center;
    }
    &-submit-btn {
      padding-right: 15px;
    }
    &-skeleton {
      width: 98% !important;
    }
  }
  .no-wifi-box {
    width: 100% !important;
  }
  .manage-dialog {
    & .MuiDialog-paper {
      min-width: 95% !important;
    }
  }
}

@media screen and (max-width: '780px') {
  .remove-device-setup {
    &-grid-col-1 {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    &-custom-input {
      .MuiInputBase-input {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: '699px') {
  .manage-dialog {
    &-swap-box {
      flex-direction: column;
    }
    &-swap-box-sub {
      width: 100% !important;
      flex-direction: column;
      align-items: center;
    }
    &-close-icon {
      position: absolute !important;
      right: 43px;
      top: 57px;
    }
    &-active-bar {
      width: 100% !important;
    }
  }

  .manage-user-block {
    margin-top: 0;
  }
  .manage-user-block .MuiInputBase-input {
    width: 100% !important;
    &::placeholder {
      font-size: 12px !important;
    }
  }

  .setting-btn-block {
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .table-device-card-res {
    padding: 20px 20px 20px !important;
  }
  .group-detail-users {
    margin-bottom: 20px;
    &-text {
      width: 100%;
    }
    &-button {
      width: 100%;
      justify-content: flex-start !important;
    }
  }

  .manage-group-device {
    &-item-list {
      width: 95% !important;
    }
    &-count-block {
      width: 95% !important;
    }
    &-left .MuiFormControl-root {
      width: 95% !important;
      .MuiInputBase-input {
        &::placeholder {
          font-size: 10px;
        }
      }
    }
    &-right .MuiFormControl-root {
      width: 95% !important;
      .MuiInputBase-input {
        &::placeholder {
          font-size: 10px;
        }
      }
    }
    &-all-select-card {
      width: 95% !important;
    }
    &-no-device {
      width: 95%;
    }
    &-skeleton {
      width: 95% !important;
    }
    &-item-text {
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    // &-all-select-card .MuiListItemButton-root {
    //   margin-right: 120px !important;
    // }
  }
}
@media screen and (max-width: '560px') {
  .manage-group-device {
    &-all-select-card .MuiListItemButton-root {
      margin-right: 167px !important;
    }
  }
}

@media screen and (max-width: '380px') {
  .remove-device-setup {
    &-custom-placeholder {
      .MuiInputBase-input {
        &::placeholder {
          font-size: 12px !important;
        }
      }
    }
  }
  .manage-user-block .MuiInputBase-input {
    &::placeholder {
      font-size: 8px !important;
    }
  }
}

@media screen and (min-width: '600px') and (max-width: '1000px') {
  .setting-btn-block {
    margin-left: 10px;
  }
}
