@media screen and (max-width: '1199px') {
  .group-list-responsive-table {
    // min-width: 900px !important;
  }
}
@media screen and (max-width: '599px') {
  .group-add {
    &-set-group-name {
      width: auto !important;
    }
    &-set-group-input {
      input {
        width: 100% !important;
        &::placeholder {
          font-size: 13px !important;
        }
      }
    }
    &-btn-card {
      flex-direction: column;
      row-gap: 8px;
    }
  }
}
@media screen and (max-width: '360px') {
  .group-add {
    &-set-group-input {
      input {
        &::placeholder {
          font-size: 10px !important;
        }
      }
    }
  }
}
