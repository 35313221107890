.corporate-input-row {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  flex-wrap: wrap;
  .corporate-input-col {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.custom-form-input {
  margin-top: 6px !important;
  margin-bottom: 8px !important;
}

.corporate-profile-row {
  .corporate-profile-col {
    width: 100%;
  }
}
