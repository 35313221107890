.personal-reg-setting,
.personal-console-setting {
  display: grid !important;
  grid-template-columns: 175px 1fr;
}
.personal-reg-setting:not(:last-child),
.personal-console-setting:not(:last-child) {
  margin-bottom: 16px;
}
.personal-reg-setting {
  align-items: baseline;
}
@media screen and (min-width: '1536px') {
  .personal-setting-row {
    width: 50%;
  }
}
@media screen and (min-width: '1200px') {
  .personal-setting-row {
    width: 50%;
  }
}
@media screen and (max-width: '1199px') {
  .personal-setting-row {
    width: 100%;
  }
}
@media screen and (max-width: '899px') {
  .reg-info-row {
    gap: 0;
  }
}
@media screen and (min-width: '600px') and (max-width: '899px') {
  .personal-reg-setting,
  .personal-console-setting {
    grid-template-columns: 150px 1fr;
  }
}
@media screen and (max-width: '599px') {
  .personal-reg-setting,
  .personal-console-setting {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }
}
