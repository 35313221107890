@media screen and (max-width: '1300px') {
  .group-list-responsive-table {
    min-width: 970px !important;
  }
}
@media screen and (max-width: '630px') {
  .group-list-responsive-flex {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    row-gap: 12px;
    form {
      width: 100%;
    }
  }
}
