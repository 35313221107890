.pagination-button-wrapper{
    margin: 0 12px 0 20px;
    padding-top: 12px;
    display: flex;
    p{
        font-size: 13px;
    };

    .pagination-icon-container{
        button{
            img{
                height: 13px;
                width: 11px;
            }
        }
    }
}