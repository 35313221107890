.device-name-input-group {
  display: flex;
  gap: 6px;
  // margin-top: -5px;
  position: relative;
}

.lost-mode-input-group {
  display: flex;
  gap: 6px;
}

.name-btns {
  display: flex;
  gap: 6px;
}
@media screen and (max-width: '1199px') {
  .device-details {
    flex-wrap: wrap;
    &-card {
      width: 100% !important;
      margin-right: 0 !important;
    }
    &-status-card {
      width: 100% !important;
      max-width: 100% !important;
      margin-top: 12px;
    }
  }
  .trans-history-card {
    flex-wrap: wrap;
    row-gap: 12px;
    & form {
      width: 100%;
      .filter-box-form-control {
        width: 100%;
      }
    }
  }
  .trans-history-date {
    width: 100%;
    justify-content: center;
  }
  .w-full {
    width: 100% !important;
    max-width: unset !important;
  }
}
@media screen and (min-width: '699px') and (max-width: '899px') {
  .pl-sm-30 {
    padding-left: 30px;
  }
}

@media screen and (max-width: '699px') {
  .device-details {
    flex-wrap: wrap;
    &-card {
      width: 100% !important;
      margin-right: 0 !important;
      flex-wrap: wrap;
      row-gap: 20px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      row-gap: 20px;
      &-box {
        margin-bottom: 0 !important;
      }
    }
    &-status-card {
      flex-wrap: wrap;
      row-gap: 20px;
      &-item {
        display: flex;
        flex-direction: column;
        width: 100% !important;
        margin: 0 !important;
        row-gap: 20px;
        &-box {
          margin: 0 !important;
        }
      }
    }
  }
  .trans-history-date {
    flex-wrap: wrap;
    row-gap: 12px;
    .trans-history-date-row {
      flex-wrap: wrap;
      flex-direction: column;
      gap: 12px;
      padding-right: 0 !important;
      width: 100%;
      .trans-history-date-row-text {
        padding-right: 0;
      }
      .trans-history-date-row-input {
        width: 100% !important;
        &-control {
          width: 100% !important;
        }
      }
    }
  }
  .no-trans-text {
    font-size: 16px !important;
    line-height: 1.5 !important;
  }
  .text-small-center {
    text-align: center;
  }
}

@media screen and (max-width: 374px) {
  .device-name-input-group,
  .lost-mode-input-group {
    flex-direction: column;
  }
}
