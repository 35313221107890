.text-center {
  text-align: center !important;
}
.trans-count-filter-item-export-item {
  display: flex;
  align-items: center;
}
@media screen and (max-width: '1450px') {
  .trans-count-filter:lang(fr) {
    flex-direction: column;
    row-gap: 12px;
  }
}
@media screen and (max-width: '1380px') {
  .trans-count-filter:lang(de) {
    flex-direction: column;
    row-gap: 12px;
  }
}
@media screen and (max-width: '1266px') {
  .trans-count-filter {
    flex-direction: column;
    row-gap: 12px;
    &-item {
      width: 100% !important;
      max-width: unset !important;
      &-export {
        column-gap: 0 !important;
      }
    }
  }
}
@media screen and (max-width: '999px') {
  .trans-count-filter-item:lang(fr) {
    flex-direction: column;
  }
}
@media screen and (max-width: '899px') {
  .trans-count-filter {
    &-item {
      flex-direction: column;
      &-date {
        width: 100% !important;
        &-control {
          width: 100% !important;
        }
      }
      &-export {
        justify-content: center !important;
      }
    }
  }
}

@media screen and (max-width: '599px') {
  .trans-count-filter {
    &-item {
      &-export {
        justify-content: center !important;
        flex-direction: column;
        row-gap: 12px;
      }
    }
  }
}

@media screen and (max-width: '374px') {
  .trans-count-filter {
    &-item {
      &-export {
        &-item {
          flex-direction: column;
          row-gap: 12px;
          .view-switch-label-responsive {
            margin: 0;
          }
        }
      }
    }
  }
}

.export-data-btn-box {
  display: flex;
  min-width: 102px;
  align-items: center;
}
.export-data-text:lang(ja) {
  min-width: 84px;
}
