.table-pagination{
  min-width: 210px !important;
}
@media screen and (max-width: '899px') {
  .filter-box {
    flex-wrap: wrap;
    row-gap: 12px;
  }
  .filter-box-item {
    width: 100% !important;
  }
  .search-input {
    width: 100% !important;
    max-width: 396px;
  }
  .filter-box-form-control {
    width: 100% !important;
  }
}
@media screen and (max-width: '599px') {
  .table-footer {
    flex-wrap: wrap;
    row-gap: 7px;
    &-item {
      justify-content: center !important;
    }
  }
}

@media screen and (max-width: '374px') {
  .table-pagination{
    min-width: 100% !important;
    row-gap: 3px;
  }
  .table-footer {
    flex-wrap: wrap;
    row-gap: 24px;
  }
  .table-pagination{
    flex-direction: column !important;
    & > .MuiGrid-root.MuiGrid-item {
      padding-top: 0 !important;
    }
  }
}