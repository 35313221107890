.res-dialog {
  &-title {
    &-text {
      max-width: 304px;
    }
  }
}
@media screen and (max-width: 575px) {
  .res-dialog {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-icon {
      width: 72px;
    }
    &-title,
    &-text,
    &-btns {
      max-width: 100% !important;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      justify-content: center !important;
    }
    &-title {
      margin-top: 10px !important;
      &-text {
        max-width: unset;
      }
    }
  }
}
