// .App {
//   // text-align: center;
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// /** react-bootstrap-tableの検索欄にデフォルトで表示される「Search this table」ラベルを消すためのスタイル */
// .sr-only {
//   display: none;
// }

// /** Material-uiのTextFieldのhelperTextに設定した文字列に指定した改行コードを適用させるためのスタイル */
// #mui-2-helper-text {
//   white-space: pre-line;
// }

.main-content {
  width: 100%;
  flex-grow: 1;
  padding: 0;
  text-align: unset;
  background-color: transparent;
  margin-top: 62px;
  height: calc(100vh - 62px);
  // overflow-y: overlay;
}
// .custom-scrollbar{
//   &::-webkit-scrollbar {
//     width: 14px;
//   }
   
//   &::-webkit-scrollbar-track {
//     box-shadow: none;
//   }
   
//   &::-webkit-scrollbar-thumb {
//     background-color: #E8E8E8;
//     outline: none;
//     border-radius: 14px;
//     border: 4px solid rgba(0, 0, 0, 0);
//     background-clip: padding-box;
//   }
// }
// simplebar scrollbar start
.simplebar-wrapper{
  padding: 0 !important;
}
.simplebar-track{
  padding: 0 7px !important;
}
.simplebar-scrollbar{
  &::before{
    background-color: #E8E8E8;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    border-radius: 6px;
  }
}
.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}
.simplebar-track.simplebar-vertical{
  .simplebar-scrollbar{
    &::before{
      top: 4px;
      bottom: 4px;
    }
  }
}

.simplebar-track.simplebar-horizontal {
  .simplebar-scrollbar {
    &::before {
      top: 0px;
      bottom: 0px;
    }
  }
}
.table-body-simplebar {
  max-height: calc(100vh - 457px)
}
@media screen and (max-width: 1440px) {
  .table-body-simplebar {
    max-height: calc(100vh - 447px)
  }
}
@media screen and (max-width:1336px) {
  .table-body-simplebar {
    max-height: calc(100vh - 437px)
  }
}
.table-body-simplebar-detail {
  max-height: calc(100vh - 505px)
}
@media screen and (max-width: 1440px) {
  .table-body-simplebar-detail {
    max-height: calc(100vh - 495px)
  }
}
@media screen and (max-width:1336px) {
  .table-body-simplebar-detail {
    max-height: calc(100vh - 485px)
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #313133;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

