.active-bar-activity-width {
  width: 86px;
}
.active-bar-activity-width:lang(ja) {
  width: 128px;
}
.active-bar-activity-width:lang(de) {
  width: 92px;
}
.active-bar-announcement-width {
  width: 139px;
}
.active-bar-announcement-width:lang(ja) {
  width: 91px;
}
.active-bar-announcement-width:lang(fr) {
  width: 94px;
}
