.device-deregister-container {
  display: flex;
}

.device-deregister-message {
  text-align: left;
  margin-bottom: 1rem;

  & p {
    margin: 0;
  }
}

.deregister-button-container {
  display: flex;
  justify-content: center;
}

.device-deregister-form {
  margin: 1rem 0;
}

.button-display-left {
  padding-right: 1rem;
}

.button-display-right {
  padding-left: 1rem;
}
