.groupdropdown {
  height: '38px';
}

.searchDropdown {
  font-size: 12px;
  font-weight: 400;
  height: 33px;
  border-radius: 6px;
  padding-left: 12px;
  padding-right:30px;
  border: 1px solid #d9d9d9;
  width: 212px;
  &:hover{
    border:1px solid #939598;
  }
  &:focus {
    outline: none;
    border:1px solid #939598;
  }
  &::-webkit-input-placeholder {
    font-size: 12px;
  }

  &::-moz-placeholder {
    font-size: 12px;
  }

  &:-ms-input-placeholder {
    font-size: 12px;
  }

  &:-moz-placeholder {
    font-size: 12px;
  }
}
