.device-table-container {
  text-align: left;
      tr {
        text-align: left;
        padding: 0px 37px 0px 20px;
        :first-child {
            color: #313133;
            text-align: left;
          }
        th:last-child{
          div{
            text-align: right;
          }
          
        }
        td:last-child {
          text-align: right;
        }
      }
}
