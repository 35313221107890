.stats {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  &-row {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
  }
  &-col {
    width: 33.33%;
    padding-left: 10px;
    padding-right: 10px;
    &-inner {
      min-height: 103px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.dashboard-trans-no-data {
  padding: 30px 0 20px;
}

@media screen and (max-width: 1199px) {
  .stats {
    &-row {
      flex-wrap: wrap;
      row-gap: 20px;
    }
    &-col {
      width: 50%;
      min-width: 50%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@media screen and (max-width: 899px) {
  .res-d-none {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .stats {
    &-col {
      width: 100%;
      min-width: 100%;
    }
  }
}

@media screen and (max-width: 599px) {
  .stats {
    &-col {
      &-inner {
        &-row {
          flex-direction: column;
          row-gap: 5px;
        }
        &-col-form {
          width: 130px;
          margin-left: unset !important;
        }
      }
    }
  }
  .res-remote-row {
    flex-direction: column;
  }
  .res-remote-bar-col {
    width: 100% !important;
  }
  .res-remote-bar {
    display: none;
  }
  .res-lost-mode {
    flex-direction: column;
    &-col {
      width: 100% !important;
    }
  }
  .dash-lang-no-data {
    height: 150px !important;
    &-inner {
      margin-top: 35px !important;
    }
  }
  .dash-chart-row {
    flex-direction: column;
    gap: 30px !important;
    &-col {
      width: 100% !important;
      margin-left: auto;
      margin-right: auto !important;
      // canvas{
      //   width: 100% !important;
      // }
    }
  }
}
