.table-header {
  white-space: pre;
  background-color: #f9fafb;
  height: 47px;
  text-align: right;
  :first-child {
    .table-header-title {
      text-align: left;
    }
  }

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.table-header-title {
  position: relative;
}

.table-header-icon {
  display: inline-block;
  margin-left: auto;
}

.table-header th:hover .hover-icon {
  visibility: visible;
  opacity: 0.5; // ソート未実施時はソートアイコンを透過(ソート中のアイコンと区別させるため)
  margin-bottom: 0;
}

.table-header .hover-icon {
  visibility: hidden;
}

.table-header-title-text {
  display: inline-block;
  color: #313133;
  opacity: 1;
  height: 20px;
  margin-left: 0px;
  font-weight: bold;
}
.table-header-icon {
  position: absolute;
  top: 5px;
}
.table-header-icon-container {
  height: 11.5px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.table-header-title-text:hover {
  cursor: pointer;
}

.table-header-icon:hover {
  cursor: pointer;
}
.device-table-header {
  white-space: pre;
  background-color: #f9fafb;
  height: 47px;
  text-align: left;
  :first-child {
    .table-header-title {
      text-align: left;
    }
  }

  & th:not(:last-child) {
    padding-right: 30px;
  }
  & th:last-child {
    div {
      justify-content: flex-start !important;
    }
  }
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.groupListTable {
  & th:not(:first-child) {
    position: relative;
    div {
      display: flex;
      justify-content: flex-end;

      .table-header-title {
        position: relative;
      }

      .table-header-icon {
        right: -18px;
      }
    }

    // padding-right: 100px;
  }

  & th:first-child {
    div {
      height: 20px;
      .table-header-icon-container {
        height: 12px;
      }
    }
  }

  & th:last-child {
    padding-right: 0px;
  }
}

.groupDeviceTable {
  & th:not(:first-child) {
    position: relative;
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .table-header-title {
        position: relative;
      }

      .table-header-icon {
        right: -18px;
      }
    }
  }

  & th:first-child {
    div {
      height: 20px;
    }
  }

  & th:last-child {
    div {
      justify-content: flex-end;
    }
  }
}

.translatioHistoryTable {
  & th:not(:first-child) {
    position: relative;
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .table-header-title {
        position: relative;
      }

      .table-header-icon {
        right: -18px;
      }
    }
  }

  & th:first-child {
    div {
      height: 20px;
    }
    div:lang(fr) {
      height: unset;
      .table-header-icon {
        top: 15px;
      }
    }
    div:lang(de) {
      height: unset;
      .table-header-icon {
        top: 15px;
      }
    }
  }
}

.groupUserTable {
  tr {
    padding-right: 20px !important;
  }
}
.report-list-header {
  font-size: 13px;
  font-weight: 500;
}
