.data-table {
  table-layout: fixed; // 自動改行設定に必要
  width: 100%;
  white-space: nowrap;
  margin-bottom: 0px;
  border: 1px solid #d9d9d9;
  border-collapse: separate;
  border-spacing: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: none;
  word-wrap: break-word;
  td {
    word-break: break-word; // 自動改行
  }

  th,
  td {
    border: none; // テーブル内の枠線消す
    font-size: 14px;
    width: 160px;
    // display: flex;
    // align-items: center;
    padding: 0;
    margin: auto 0px;
  }
  tr {
    padding: 0px 37px 0px 20px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;
    --bs-table-hover-bg: none;
  }
  th:first-child {
    border-top-left-radius: 4px;
  }
  th:last-child {
    border-top-right-radius: 4px;
  }
}

.data-table > :not(:first-child) {
  border-top: none;
}

.data-table-body {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  tr {
    text-align: right;
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        display: block;
        text-align: right;
      }
      &:first-child {
        a {
          text-align: left;
        }
      }
    }
  }
}

.device-data-table-body {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  tr {
    text-align: left;
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        display: block;
        text-align: left;
      }
      &:first-child {
        a {
          text-align: left;
        }
      }
    }
  }
}

.report-table {
  th,
  td {
    //min-width: 130px;
    &:nth-child(1) {
      div {
        justify-content: flex-start !important;
        text-align: left !important;
      }
    }
    &:nth-child(2) {
      //min-width: 160px;
    }
  }
  thead tr {
    padding: 0px 37px 0px 20px !important;
  }
  tbody tr {
    padding-right: 37px !important;
  }
  thead tr th div {
    justify-content: flex-end !important;
  }
  tbody tr td div {
    text-align: right !important;
  }
}
.report-table-transCount {
  th,
  td {
    //min-width: 130px;
    &:nth-child(1) {
      //min-width: 170px;
      div {
        justify-content: flex-start !important;
        text-align: left !important;
      }
    }
    &:nth-child(2) {
      //min-width: 190px;
      div {
        justify-content: flex-start !important;
        text-align: left !important;
      }
    }
    &:nth-child(3) {
      //min-width: 230px;
      div {
        justify-content: flex-start !important;
        text-align: left !important;
        padding-right: 10px;
      }
    }
    &:nth-child(4) {
      //min-width: 140px;
      div {
        justify-content: flex-start !important;
        text-align: left !important;
      }
    }
    &:nth-child(5) {
      div {
        justify-content: flex-start !important;
        text-align: left !important;
      }
    }
    &:nth-child(6) {
      //min-width: 130px;
    }
  }
  thead tr {
    padding-right: 37px !important;
  }
  tbody tr {
    padding-right: 37px !important;
  }
  thead tr th div {
    text-align: right !important;
    justify-content: flex-end !important;
  }
  tbody tr td div {
    text-align: right !important;
  }
}
