.monthRangeParent {
  max-width: 430px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 608px;
  border-radius: 6px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1215686275);
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-bottom: 10px;
}


.monthRange {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.calenderContainer {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
}

.cellBasic {
  width: 60px;
  height: 40px;
  cursor: pointer;
}

.cellInside {
  font-size: 14px;
  height: 33px;
  padding: 5px;
  margin: 2px;
  text-align: center;
  border-radius: 6px;
}
.cellInside:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.cellInside_strick {
  font-size: 14px;
  height: 33px;
  padding: 5px;
  margin: 2px;
  text-align: center;
  border-radius: 6px;
  text-decoration: line-through;
  opacity: 0.5;
}
.startMonthCell {
  background-color: #009bdf;
  box-shadow: none;
  color: #ffffff;
}

.startMonthCell:hover {
  background-color: #007eb4;
}

.hoverCell {
  background-color: #d6f3ff;
}

.hoverCell:hover {
  opacity: 0.7;
}

.endMonthCell {
  background-color: #009bdf;
  box-shadow: none;
  color: #ffffff;
}

.endMonthCell:hover {
  background-color: #007eb4;
}

.normalCell {
  background-color: white;
}

.leftCalender {
  padding: 20px 0px 10px 20px;
  display: flex;
  flex-direction: column;
}

.headerCalenderLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
}

.leftButtonChange {
  margin-left: 5px;
  text-align: center;
}
.calenderYearRight {
  margin: 0;
  margin-left: auto;
  padding-right: 35px;
  flex-grow: 1;
  text-align: center;
}

.calenderBar {
  margin-top: 5px;
  margin-bottom: 5px;
  align-self: center;
  width: 93%;
  height: 1px;
  background-color: black;
  opacity: 0.15;
}

.rightCalender {
  padding: 20px 20px 10px 0px;
  display: flex;
  flex-direction: column;
}

.headerCalenderRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rightButtonChange {
  margin-right: 5px;
  text-align: center;
}
.calenderYearLeft {
  margin: 0;
  margin-left: auto;
  padding-left: 35px;
  flex-grow: 1;
  text-align: center;
}

.changeYearButton {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: transparent;
  white-space: nowrap;
  opacity: 0.5;
}
.changeYearButton:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.accessoriesCalenderBar {
  margin-top: 5px;
  margin-bottom: 5px;
  align-self: center;
  width: 100%;
  height: 1px;
  background-color: black;
  opacity: 0.15;
}

.accessories {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.applyButton {
  cursor: pointer;
  padding: 8px 20px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background-color: #009de1;
  border: none;
  border-radius: 6px;
  margin-right: 8px;
  margin-left: 8px;
}

.applyButton:disabled {
  padding: 8px 20px 10px 20px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  border: 0;
  color: #ffffff;
  opacity: 0.5;
  background-color: #009de1;
}
.applyButton:hover {
  background-color: #007eb4;
}

.cancelButton {
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 7px 19px 9px 19px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #313133;
  opacity: 1;
}

.cancelButton:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media screen and (max-width: 450px) {
  .monthRangeParent {
    max-width: 300px;
  }
  .cellBasic {
    width: 48px;
  }
  .leftCalender {
    padding: 20px 0px 10px 5px;
  }  
.rightCalender {
  padding: 20px 5px 10px 0px;
}
  
}
