body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:focus-visible {
  outline: none;
}
// html {
//   overflow: scroll;
//   overflow-x: hidden;
// }
// ::-webkit-scrollbar {
//   width: 0; /* Remove scrollbar space */
//   background: transparent; /* Optional: just make scrollbar invisible */
// }
// /* Optional: show position indicator in red */
// ::-webkit-scrollbar-thumb {
//   background: #e7e4e4;
// }

// html {
//   overflow: scroll;
//   overflow-x: hidden;
// }
// body::-webkit-scrollbar {
//   width: 6px;
// }

// body::-webkit-scrollbar-track {
//   box-shadow: none;
// }

// body::-webkit-scrollbar-thumb {
//   // background-color: #E8E8E8;
//   background-color: red;
//   outline: none;
//   border-radius: 6px;
//   // border: 4px solid rgba(0, 0, 0, 0);
//   // background-clip: padding-box;
//   scroll-padding-right: 4px;
// }

@import 'responsive/common/header.scss';
@import 'responsive/common/main-content.scss';
@import 'responsive/common/table-pagination.scss';
@import 'responsive/common/dialogs.scss';
@import 'responsive/password-setup.scss';
@import 'responsive/dashboard.scss';
@import 'responsive/personal-settings.scss';
@import 'responsive/corporate-profile.scss';
@import 'responsive/device-list.scss';
@import 'responsive/device-list-details.scss';
@import 'responsive/times-translated.scss';
@import 'responsive/user-list.scss';
@import 'responsive/device-list-details.scss';
@import 'responsive/group-list.scss';
@import 'responsive/group-details.scss';
@import 'responsive/group-add.scss';
@import 'responsive/notification.scss';
