.search-input-fr {
  .MuiInputBase-input {
    &::placeholder {
      font-size: 11px;
    }
  }
}
@media screen and (max-width: '1820px') {
  .device-table-header-res {
    width: 1818px !important;
  }
  .table-body-device-res {
    width: 1818px !important;
  }
}
@media screen and (max-width: '1199px') {
  .search-input {
    width: 265px !important;
    padding: 0 4px 0 8px !important;
    .MuiInputBase-input {
      &::placeholder {
        font-size: 10px;
      }
    }
    &-icon {
      margin-left: 0px !important;
    }
  }
  .search-input-fr {
    width: 265px !important;
    padding: 0 4px 0 8px !important;
    .MuiInputBase-input {
      &::placeholder {
        font-size: 6.9px;
      }
    }
    &-icon {
      margin-left: 0px !important;
    }
  }
}
@media screen and (max-width: '899px') {
  .filter-box {
    flex-wrap: wrap;
    row-gap: 12px;
  }
  .filter-box-item {
    width: 100% !important;
  }
  .search-input {
    width: 100% !important;
    max-width: 396px;
    padding: 0 8px 0 16px !important;
    .MuiInputBase-input {
      &::placeholder {
        font-size: 14px;
      }
    }
    &-icon {
      margin-left: 8px !important;
    }
  }
  .search-input-fr {
    width: 100% !important;
    max-width: 396px;
    padding: 0 8px 0 16px !important;
    .MuiInputBase-input {
      &::placeholder {
        font-size: 11px;
      }
    }
    &-icon {
      margin-left: 8px !important;
    }
  }
  .search-input-list {
    width: 360px !important;
    padding: 0 4px 0 8px !important;
    .MuiInputBase-input {
      &::placeholder {
        font-size: 13px;
      }
    }
    &-icon {
      margin-left: 0px !important;
      padding: 4px !important;
    }
  }
  .filter-box-form-control {
    width: 100% !important;
  }
}
@media screen and (max-width: '599px') {
  .search-input {
    width: 100% !important;
    max-width: 396px;
    padding: 0 4px 0 8px !important;
    .MuiInputBase-input {
      &::placeholder {
        font-size: 10px;
      }
    }
    &-icon {
      margin-left: 0px !important;
      padding: 4px !important;
    }
  }
  .search-input-fr {
    width: 100% !important;
    max-width: 396px;
    padding: 0 4px 0 8px !important;
    .MuiInputBase-input {
      &::placeholder {
        font-size: 8px;
      }
    }
    &-icon {
      margin-left: 0px !important;
      padding: 4px !important;
    }
  }
  .search-input-list {
    width: 100% !important;
    max-width: 396px;
    padding: 0 4px 0 8px !important;
    .MuiInputBase-input {
      &::placeholder {
        font-size: 13px;
      }
    }
    &-icon {
      margin-left: 0px !important;
      padding: 4px !important;
    }
  }
  .filter-box-item-child {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  }
  .filter-box-item-select {
    width: 100% !important;
    & .select-group {
      max-width: 100% !important;
    }
  }
}
@media screen and (max-width: '455px') {
  .search-input-list {
    .MuiInputBase-input {
      &::placeholder {
        font-size: 9.4px;
      }
    }
  }
}
