.device-deregister-complete-message {
  text-align: left;
  margin-bottom: 1rem;

  & p {
    margin: 0;
  }
}

.device-deregister-complete-form {
  margin: 1rem 0;
}
